/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
import {getScores} from "../../api";

const useScores = () => {
    const interval = useRef();
    const [scores, setScores] = useState([]);

    const handleScoresAndDetectNew = (newScores) => {
        let previousIndex = 0;
        if (scores.length === 0) {
            setScores(newScores);
            return;
        }

        if (newScores.length === scores.length) return;

        const scoresWithNew = newScores.map((score, idx) => {
            const index = idx - previousIndex;
            if (score.name === scores[index]?.name && score.score === scores[index]?.score && score.timeInMilliseconds === scores[index]?.timeInMilliseconds) {
                return score;
            } else {
                previousIndex++;
                return {...score, new: true};
            }
        });
        setScores(scoresWithNew);
    }

    useEffect(() => {
        getScores().then(data => handleScoresAndDetectNew(data));
    }, []);

    useEffect(() => {
        interval.current = setInterval(() => getScores().then(data => handleScoresAndDetectNew(data)), 5000);
        return () => clearInterval(interval.current);
    }, [scores])

    return scores;
}

export {useScores};