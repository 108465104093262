/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {LowScores} from "./LowScores";
import {useScores} from "./hooks/useScores";
import {Score} from "./Score";
import useSound from "use-sound";

const Scores = () => {
    const scores = useScores();
    const [highScores, setHighScores] = React.useState(scores);
    const [first] = useSound('./first.mp3')
    const [secondThird] = useSound('./second-third.mp3');
    const [rest] = useSound('./toot.mp3')

    React.useEffect(() => {
        scores.forEach((score, index) => {
            if (score.new) {
                if (index === 0) {
                    first();
                } else if (index > 0 && index < 3) {
                    secondThird();
                } else {
                    rest();
                }
            }
        })
    }, [scores])

    React.useEffect(() => {
        setHighScores(scores.slice(0, 3));
    }, [scores]);

    return (
        <div className={'w-full flex flex-col items-center align-center pt-12'}>
            {highScores.map((score, index) => <Score key={`hs-${index}`} score={score} index={index}/>)}
            <LowScores/>
        </div>
    );
};

export {Scores};