import React from "react";
import {addRaffleEntry} from "../api";

function SuccessAlert({name}) {
    return (
        <div align="center">
            <p className={'rainbow-text mt-10 border rounded-2xl border-white p-2'}>{`Thank you, ${name}! You have been entered in the raffle.`}</p>
        </div>
    );
}

const RaffleForm = () => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState("")

    function onSubmit() {
        if (!name || !email) {
            setError('Name and Email are required pweaaase.')
            return;
        }
        addRaffleEntry(name, email, title, company).then(() => {
            setSuccess(true);
        });
    }

    return (
        <>
            {success && <SuccessAlert name={name}/>}
            {!success && <div className={'entryForm'}>
                <div className={'entryInput'}>
                    <div>
                        <label className={'text-white mt-10 label'} htmlFor="name">Name</label>
                        <input className={'text-black mt-10 border rounded-2xl border-white p-2'} id="name" value={name}
                               required={true} onChange={(event) => setName(event.target.value)}/>
                    </div>
                    <div>
                        <label className={'text-white mt-10 label'} htmlFor="email">Email</label>
                        <input className={'text-black mt-10 border rounded-2xl border-white p-2'} id="email"
                               value={email}
                               required={true} onChange={(event) => setEmail(event.target.value)}/>
                    </div>
                    <div>
                        <label className={'text-white mt-10 label'} htmlFor="title">Job Title</label>
                        <input className={'text-black mt-10 border rounded-2xl border-white p-2'} id="title"
                               value={title}
                               onChange={(event) => setTitle(event.target.value)}/>
                    </div>
                    <div>
                        <label className={'text-white mt-10 label'} htmlFor="company">Company</label>
                        <input className={'text-black mt-10 border rounded-2xl border-white p-2'} id="company"
                               value={company}
                               onChange={(event) => setCompany(event.target.value)}/>
                    </div>
                </div>
                <p className={'mt-8 mb-2 text-red-500 self-end'}>{error ? error : ''}</p>
                <button
                    className={'submit text-white border rounded-2xl border-white p-2 hover:bg-white hover:text-black'}
                    name="submit" onClick={onSubmit}>Submit
                </button>
            </div>}
        </>
    )
};

export {
    RaffleForm
};