import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import axios from 'axios';
import {BrowserRouter} from "react-router-dom";

axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_XAPIKEY;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);