import React from 'react';
import {Header} from "./Components/Header";
import {Scores} from "./Components/Scores";
import {Route, Routes} from "react-router-dom";
import {Raffle} from "./Components/Raffle";
import {RaffleForm} from "./Components/RaffleForm";

const App = () => {

    return (
        <div className={'flex flex-col justify-center items-center w-full'}>
            <Header/>
            <Routes>
                <Route path={"/enter-raffle"} element={<RaffleForm/>}/>
                <Route path={"/raffle"} element={<Raffle/>}/>
                <Route path="*" element={<Scores/>}/>
            </Routes>
        </div>);
}

export {App};
