import React from 'react';
import {ScoreContent} from "./ScoreContent";

const BASE_WRAPPER = 'text-center border-2 border-white rounded-2xl my-4 py-2';

const Score = (props) => {
    const {index, score} = props;

    const sizeStyle = () => {
        if (index === 0) {
            return {
                score: 'text-5xl font-bold h-30% my-2 rainbow-text w-full',
                wrapper: `${BASE_WRAPPER} h-60 bg-black glow-border mb-8 w-4/5`,
                style: {top: '10rem'}
            };
        } else if (index === 1) {
            return {
                score: 'text-3xl font-bold h-25% my-2 glow w-full',
                wrapper: `${BASE_WRAPPER} h-52 bg-black glow-border w-3/5`,
                style: {top: '21rem'}
            }
        } else if (index === 2) {
            return {
                score: 'text-3xl font-bold h-20% my-2 glow w-full',
                wrapper: `${BASE_WRAPPER} h-44 bg-black glow-border w-2/5`,
                style: {top: '30rem'}
            }
        } else return {
            score: 'text-white w-fit',
            wrapper: `${BASE_WRAPPER} px-4 mr-8`
        }
    }

    return (
        <div className={sizeStyle().wrapper} style={{...sizeStyle().style}}>
            < div className={sizeStyle().score} style={{fontFamily: 'pixel'}}>
                <ScoreContent score={score} index={index}/>
            </div>
        </div>
    );
};

export {Score};