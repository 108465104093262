/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {getRaffleEntrants} from "../api";
import {useScores} from "./hooks/useScores";
import {RaffleThinger} from "./RaffleThinger";
import {shuffleArray} from "../utils/utils";

const Raffle = () => {
    const scores = useScores();
    const [entrants, setEntrants] = React.useState([]);

    React.useEffect(() => {
        entrants.length === 0 && scores.length > 0 && getRaffleEntrants().then(data => {
            const nonHighScores = scores.slice(3, scores.length);
            let shuffledArray = [...data, ...nonHighScores];
            shuffleArray(shuffledArray);
            setEntrants(shuffledArray);
        });
    }, [scores]);

    return <RaffleThinger emails={entrants.map(ent => ent.name)}/>

};

export {Raffle};