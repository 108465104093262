import React from 'react';
import {useScores} from "./hooks/useScores";
import {Score} from "./Score";
import Marquee from "react-fast-marquee";

const LowScores = () => {
    const scores = useScores();
    const [lowScores, setLowScores] = React.useState(scores);

    React.useEffect(() => {
        setLowScores(scores.slice(3, scores.length));
    }, [scores]);

    return (
        <div style={{display: 'grid', gridAutoFlow: 'column', marginTop: '.5rem'}}>
            <Marquee speed={100} direction={"right"} pauseOnClick={true}>
                {lowScores.map((ls, index) => <Score key={`ls-${index}`} score={ls} index={index + 3}/>)}
            </Marquee>
        </div>
    );
};

export {LowScores};