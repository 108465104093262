import React from 'react';
import {Confetti} from "./Confetti";

const getStringLabel = (index) => {
    const sIndex = String(index + 1);
    let label;
    switch (sIndex[sIndex.length - 1]) {
        case '1':
            label = 'ST';
            break;
        case '2':
            label = 'ND';
            break;
        case '3':
            label = 'RD';
            break;
        default:
            label = 'TH';
    }
    return `${index + 1}${label}`;
};

const formatDuration = (score) => {
    let ms = score.timeInMilliseconds;
    if (score.ms < 0) ms = -ms;
    const time = {
        day: Math.floor(ms / 86400000),
        hour: Math.floor(ms / 3600000) % 24,
        minute: Math.floor(ms / 60000) % 60,
        second: Math.floor(ms / 1000) % 60,
        millisecond: Math.floor(ms) % 1000
    };
    return Object.entries(time)
        .filter(val => val[1] !== 0)
        .map(([key, val]) => `${val} ${key}${val !== 1 ? 's' : ''}`)
        .join(', ');
};

const ScoreContent = (props) => {
    const {score, index} = props;

    return (
        <div className={'flex flex-col items-center justify-evenly w-full'}>
            <div className={'my-2'}>{getStringLabel(index)}</div>
            <Confetti score={score} index={index}/>
            <h1 className={'uppercase my-2'}>{score.name}</h1>
            <div className={'flex items-center justify-center'}>
                <p className={'my-2'}>Score of {score.score || 0}{/* with {formatDuration(score) || 0} seconds remaining*/}</p>
            </div>
        </div>
    );
};

export {ScoreContent};