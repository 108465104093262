/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import ConfettiExplosion from "react-confetti-explosion";

const mediumProps = {
    force: 0.6,
    duration: 3000,
    particleCount: 300,
    width: 1500,
    colors: ['#9A0023', '#FF003C', '#AF739B', '#FAC7F3', '#F7DBF4'],
};

const largeProps = {
    force: 1,
    duration: 4000,
    particleCount: 500,
    width: 2000,
    colors: ['#041E43', '#1471BF', '#5BB4DC', '#FC027B', '#66D805'],
};

const getSize = (index) => {
    if (index === 0) {
        return largeProps;
    } else if (index > 0 && index < 3) {
        return mediumProps;
    }
};

const Confetti = (props) => {
    const {index, score} = props;
    const [splosion, setSplosion] = useState(false);

    useEffect(() => {
        if (score.new === true) {
            score.new = false;
            if (index > 2) return;
            setTimeout(() => {
                setSplosion(true);
                setTimeout(() => setSplosion(false), 5000)
            }, 2000);
        }
    }, [score, splosion]);

    return (<>
        {splosion && <ConfettiExplosion {...getSize(index)} />}
    </>);
}


export {Confetti, largeProps};