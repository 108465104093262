import React from 'react';
import {largeProps} from "./Confetti";
import ConfettiExplosion from "react-confetti-explosion";
import useSound from 'use-sound';

const getTimeout = (limit, count) => {
    const percentage = count / limit;
    if (percentage < .2) {
        return 1;
    } else if (percentage < .4) {
        return 20;
    } else if (percentage < .7) {
        return 50;
    } else if (percentage < .9) {
        return 100;
    } else if (percentage < .98) {
        return 200;
    } else return 500;
};

const RaffleThinger = (props) => {
    const {emails} = props;

    const interval = React.useRef();
    const [spinnerWidth, setSpinnerWidth] = React.useState(0);
    const [index, setIndex] = React.useState(0);
    const [spinning, setSpinning] = React.useState(false);
    const [limit, setLimit] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [winner, setWinner] = React.useState(null);

    const [cheer] = useSound('./cheer.mp3')
    const [ding] = useSound('./ding.mp3', {playbackRate: 1.1 - count / limit});

    React.useEffect(() => {
        const max = Math.max(...emails.map(e => {
            return e.length
        }));
        setSpinnerWidth(max * 16);
    }, [emails]);

    React.useEffect(() => {
        if (!spinning) return;
        ding();
        setCount(count + 1);
        interval.current = setInterval(() => setIndex(index === emails.length - 1 ? 0 : index + 1), getTimeout(limit, count));
        return () => clearInterval(interval.current);
    }, [spinning, index]);

    React.useEffect(() => {
        if (count >= limit) {
            setSpinning(false);
            setTimeout(() => setWinner(emails[index]), 1000);
        }
    }, [count]);

    React.useEffect(() => {
        if (winner) cheer();
    }, [winner]);

    const getRaffleEmails = () => {
        const raffleEmails = [...emails];
        if (index > emails.length - 3) {
            const firstChunk = raffleEmails.slice(index - 2, raffleEmails.length);
            const secondChunk = raffleEmails.slice(0, raffleEmails.length - index === 1 ? 2 : 1);
            return [...firstChunk, ...secondChunk];
        } else if (index < 2) {
            const start = index === 0 ? raffleEmails.length - 2 : raffleEmails.length - 1;
            const end = index === 0 ? start + 2 : start + 1;
            const firstChunk = raffleEmails.slice(start, end);
            const secondChunk = raffleEmails.slice(0, index === 0 ? 3 : 4);
            return [...firstChunk, ...secondChunk];
        } else {
            return raffleEmails.slice(index - 2, index + 3);
        }
    };

    return (
        <>
            <h1 className={'mt-4 text-6xl text-white rainbow-text'} style={{fontFamily: 'pixel'}}>THE RAFFLE</h1>
            <div className={'raffleThinger mt-10'} style={{fontFamily: 'pixel', width: `${spinnerWidth}px`}}>
                {getRaffleEmails().map((email, i) =>
                    <p key={`email-${i}`} className={`${i === 2 && 'glow'}`}
                       style={{textDecoration: ((index - i) % 2 === 0) ? 'underline' : ''}}>
                        {email}</p>)}
            </div>
            {!limit && <button
                className={'text-white mt-10 border rounded-2xl border-white p-2 hover:bg-white hover:text-black'}
                onClick={() => {
                    setSpinning(true);
                    setLimit(Math.round(emails.length * 5 * Math.random()))
                }}>RAFFLE!
            </button>}
            {winner && <div
                className={'mt-8 flex flex-row justify-center items-center border border-white rounded-2xl glow-border p-4'}>
                <ConfettiExplosion {...largeProps}/>
                <h1 className={'text-6xl text-white rainbow-text capitalize'}
                    style={{fontFamily: 'pixel'}}>{winner} WINS!</h1>
                <ConfettiExplosion {...largeProps}/>
            </div>}

        </>
    );
};

export {RaffleThinger};